var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-pull-refresh',{attrs:{"disabled":_vm.fillUpload || _vm.fillCarEval || _vm.fillContact || _vm.fillView},on:{"refresh":_vm.orderDetail},model:{value:(_vm.isPullLoading),callback:function ($$v) {_vm.isPullLoading=$$v},expression:"isPullLoading"}},[_c('div',{staticClass:"home-content"},[_c('van-overlay',{staticClass:"dis_flex ali_it_cen ju_con_cen",attrs:{"show":_vm.pageLoading,"z-index":"1000"}},[_c('van-loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.pageLoading),expression:"pageLoading"}]},[_vm._v("刷新中...")])],1),(_vm.bizNo)?[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"dis_flex ali_it_cen ju_con_bet font_32 m_t_60"},[_c('div',[_vm._v(_vm._s(_vm.detail.customerName))]),_c('div',[_vm._v(_vm._s(_vm.detail.applyProductName))])]),_c('div',{staticClass:"dis_flex ali_it_cen ju_con_cen m_t_48"},[_c('div',{staticClass:"main-info-bloc dis_flex ali_it_cen"},[_c('div',{staticClass:"text_ali_cen flex_1"},[_c('div',[_c('span',{staticClass:"font_numb"},[_vm._v(_vm._s(_vm.detail.applyAmount))]),_c('span',{staticClass:"m_l_10"},[_vm._v("元")])]),_c('div',[_vm._v("申请金额")])]),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"text_ali_cen flex_1"},[_c('div',[_c('span',{staticClass:"font_numb"},[_vm._v(_vm._s(_vm.detail.applyTerm))]),_c('span',{staticClass:"m_l_10"},[_vm._v("期")])]),_c('div',[_vm._v("申请期限")])])])])]),_c('div',{staticClass:"apply-info block"},[_c('div',{staticClass:"info-title"},[_vm._v("申请信息")]),(_vm.detail.canEvalVehicle == 'Y')?_c('van-field',{class:[_vm.detail.vehicleEvaluated == 'Y' ? 'end' : ''],attrs:{"placeholder":_vm.detail.vehicleEvaluated == 'Y' ? '已完成' : '去评估',"label":"车辆评估","readonly":"","is-link":_vm.detail.vehicleEvaluated != 'Y',"input-align":"right"},on:{"click":function($event){return _vm.handleJump({
              processName: '车辆评估',
              ..._vm.detail,
              materialList: _vm.materialList
            })}}}):_vm._e(),(_vm.unAppoint)?_vm._l((_vm.infosFields),function(block,idx){return _c('van-field',{key:idx,class:[block.complete ? 'end' : ''],attrs:{"label":block.name,"placeholder":block.tips,"readonly":"","is-link":!block.complete,"input-align":"right"},on:{"click":function($event){return _vm.openFieldsEdit(block)}},model:{value:(block.textvalue),callback:function ($$v) {_vm.$set(block, "textvalue", $$v)},expression:"block.textvalue"}})}):_vm._e()],2),_c('div',{staticClass:"quota-block container p_l_24 p_r_24 font_28"},[(_vm.detail.appStatus && _vm.detail.appStatus != '1')?[(_vm.unAppoint)?_c('div',{staticClass:"quota-item dis_flex ali_it_cen ju_con_bet",on:{"click":function($event){return _vm.handleJump({
                processName: '联系人信息',
                ..._vm.detail
              })}}},[_c('div',[_vm._v("联系人信息")]),_c('div',{class:[
                'home-btn',
                `home-btn_${_vm.detail.contactPersonSaved == 'Y' ? '1' : '0'}`
              ]},[_vm._v(" "+_vm._s(_vm.detail.contactPersonSaved == "Y" ? "已完成" : "去完善")+" ")])]):_vm._e(),(_vm.materialList && _vm.materialList.length > 0 && _vm.unAppoint)?_c('div',{staticClass:"quota-item dis_flex ali_it_cen ju_con_bet",on:{"click":function($event){return _vm.handleJump({
                processName: '进件材料',
                ..._vm.detail
              })}}},[_c('div',[_vm._v("进件材料")]),_c('div',{class:[
                'home-btn',
                `home-btn_${_vm.detail.applyImagesSaved == 'Y' ? '1' : '0'}`
              ]},[_vm._v(" "+_vm._s(_vm.detail.applyImagesSaved == "Y" ? "已完成" : "去完善")+" ")])]):_vm._e(),(_vm.customerServiceStatus == 'Y')?_c('div',{staticClass:"quota-item dis_flex ali_it_cen ju_con_bet",on:{"click":function($event){return _vm.handleJump({
                processName: '专属客服',
                ..._vm.detail
              })}}},[_c('div',[_vm._v("专属客服")]),_c('div',{class:[
                'home-btn',
                `home-btn_${_vm.detail.additionStatus == '1' ? '1' : '0'}`
              ]},[_vm._v(" "+_vm._s(_vm.detail.additionStatus == "1" ? "已添加" : "去添加")+" ")])]):_vm._e(),(_vm.detail.isSubscribe == 'Y')?_c('div',{staticClass:"quota-item dis_flex ali_it_cen ju_con_bet",on:{"click":function($event){return _vm.handleJump({
                processName: '关注公众号',
                ..._vm.detail
              })}}},[_c('div',[_vm._v("关注公众号")]),_c('div',{class:[
                'home-btn',
                `home-btn_${_vm.detail.subscribeStatus == '已关注' ? '1' : '0'}`
              ]},[_vm._v(" "+_vm._s(_vm.detail.subscribeStatus)+" ")])]):_vm._e()]:_vm._e(),_c('div',{staticClass:"bottom-tips"},[_vm._v("下拉即可刷新页面")])],2)]:[_c('div',{staticClass:"dis_flex ali_it_cen ju_con_cen m_t_48 font_36"},[_vm._v(" 请从短信链接进入 ")])],_c('FillInvUpload',{attrs:{"detail":_vm.detail},on:{"update":_vm.orderDetail},model:{value:(_vm.fillUpload),callback:function ($$v) {_vm.fillUpload=$$v},expression:"fillUpload"}}),_c('FillCarEval',{attrs:{"unAppoint":_vm.unAppoint,"detail":_vm.detail},on:{"update":_vm.orderDetail},model:{value:(_vm.fillCarEval),callback:function ($$v) {_vm.fillCarEval=$$v},expression:"fillCarEval"}}),_c('FillContact',{attrs:{"detail":_vm.detail},on:{"update":_vm.orderDetail},model:{value:(_vm.fillContact),callback:function ($$v) {_vm.fillContact=$$v},expression:"fillContact"}}),_c('Service',{attrs:{"bizNo":_vm.bizNo,"url":_vm.url},model:{value:(_vm.serviceShow),callback:function ($$v) {_vm.serviceShow=$$v},expression:"serviceShow"}}),_c('FillIn',{attrs:{"blockKey":_vm.blockKey,"blockName":_vm.blockName,"blockFields":_vm.blockFields},on:{"updateData":_vm.updateData},model:{value:(_vm.fillView),callback:function ($$v) {_vm.fillView=$$v},expression:"fillView"}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }